import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMapMarkerAlt, faUsers } from '@fortawesome/free-solid-svg-icons'

const ExchangeContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
`

const ExchangeContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
  }
`

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`

const EventCard = styled.div`
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .content {
    padding: 1.5rem;
  }

  h3 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  .info {
    display: flex;
    align-items: center;
    color: var(--text-light);
    margin-bottom: 0.5rem;

    svg {
      margin-right: 0.5rem;
      color: var(--primary-color);
    }
  }

  .description {
    color: var(--text-light);
    margin: 1rem 0;
  }

  button {
    background-color: var(--primary-color);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    transition: var(--transition);

    &:hover {
      background-color: #2980b9;
    }
  }
`

const ProgramsSection = styled.section`
  margin-top: 4rem;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-color);
  }
`

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`

const ProgramCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    margin-bottom: 1rem;
  }

  .details {
    color: var(--text-light);
    font-size: 0.9rem;
  }
`

const events = [
  {
    id: 1,
    title: 'Festival des Cultures Croisees',
    date: '15-20 Mars 2024',
    location: 'Paris, France',
    participants: '1000+ attendus',
    image: '/images/festival-culturel.jpg',
    description: 'Un festival celebrant la richesse culturelle franco-marocaine a travers l\'art, la musique et la gastronomie.'
  },
  {
    id: 2,
    title: 'Semaine du Cinema Marocain',
    date: '5-12 Avril 2024',
    location: 'Lyon, France',
    participants: '500+ attendus',
    image: '/images/cinema-marocain.jpg',
    description: 'Projection de films marocains contemporains et classiques, suivie de debats avec les realisateurs.'
  },
  {
    id: 3,
    title: 'Exposition Art Contemporain',
    date: '1-30 Mai 2024',
    location: 'Casablanca, Maroc',
    participants: '2000+ attendus',
    image: '/images/art-contemporain.jpg',
    description: 'Une exposition mettant en lumiere les artistes contemporains francais et marocains.'
  }
]

const programs = [
  {
    title: 'Programme d\'Echange Artistique',
    description: 'Residence d\'artistes permettant aux createurs francais et marocains de collaborer sur des projets communs.',
    duration: '3 mois',
    deadline: '1er Fevrier 2024'
  },
  {
    title: 'Atelier de Cuisine Traditionnelle',
    description: 'Apprentissage des techniques culinaires traditionnelles des deux pays avec des chefs renommes.',
    duration: '2 semaines',
    deadline: '15 Mars 2024'
  },
  {
    title: 'Programme Musical Interculturel',
    description: 'Collaboration entre musiciens traditionnels et contemporains des deux pays.',
    duration: '6 mois',
    deadline: '30 Avril 2024'
  }
]

function CulturalExchange() {
  return (
    <ExchangeContainer>
      <ExchangeContent>
        <Header>
          <h1>Echanges Culturels</h1>
          <p>
            Decouvrez les evenements et programmes qui celebrent la richesse
            culturelle de la France et du Maroc. Participez a des experiences
            uniques qui renforcent les liens entre nos deux pays.
          </p>
        </Header>

        <section>
          <h2>Evenements a Venir</h2>
          <EventsGrid>
            {events.map(event => (
              <EventCard key={event.id}>
                <img src={event.image} alt={event.title} />
                <div className="content">
                  <h3>{event.title}</h3>
                  <div className="info">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <span>{event.date}</span>
                  </div>
                  <div className="info">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span>{event.location}</span>
                  </div>
                  <div className="info">
                    <FontAwesomeIcon icon={faUsers} />
                    <span>{event.participants}</span>
                  </div>
                  <p className="description">{event.description}</p>
                  <button>En savoir plus</button>
                </div>
              </EventCard>
            ))}
          </EventsGrid>
        </section>

        <ProgramsSection>
          <h2>Programmes d'Echange</h2>
          <ProgramsGrid>
            {programs.map((program, index) => (
              <ProgramCard key={index}>
                <h3>{program.title}</h3>
                <p>{program.description}</p>
                <div className="details">
                  <p><strong>Duree :</strong> {program.duration}</p>
                  <p><strong>Date limite :</strong> {program.deadline}</p>
                </div>
              </ProgramCard>
            ))}
          </ProgramsGrid>
        </ProgramsSection>
      </ExchangeContent>
    </ExchangeContainer>
  )
}

export default CulturalExchange
