import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: white;
  padding: 2rem 0;
  margin-top: auto;
`

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`

const FooterSection = styled.div`
  h3 {
    color: #ecf0f1;
    margin-bottom: 1rem;
  }

  a {
    color: #bdc3c7;
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
    
    &:hover {
      color: #3498db;
    }
  }
`

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  a {
    color: #ecf0f1;
    font-size: 1.5rem;
    
    &:hover {
      color: #3498db;
    }
  }
`

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #34495e;
  color: #bdc3c7;
`

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>France-Maroc.fr</h3>
          <p>Votre portail pour les échanges culturels, économiques et éducatifs entre la France et le Maroc.</p>
        </FooterSection>
        
        <FooterSection>
          <h3>Liens Rapides</h3>
          <a href="/blog/">Blog</a>
          <a href="https://socy.fr">socy.fr</a>
          <a href="/contact">Contact</a>
        </FooterSection>
        
        <FooterSection>
          <h3>Contact</h3>
          <p>Email: contact@socy.fr</p>
          <SocialLinks>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </SocialLinks>
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        <p>&copy; {new Date().getFullYear()} France-Maroc.fr. Tous droits réservés.</p>
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
