import { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faTag, faUser } from '@fortawesome/free-solid-svg-icons'

const NewsContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
`

const NewsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
  grid-column: 1 / -1;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
  }
`

const ArticlesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const ArticleCard = styled.article`
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .content {
    padding: 1.5rem;
  }

  h2 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  .meta {
    display: flex;
    gap: 1.5rem;
    color: var(--text-light);
    margin-bottom: 1rem;
    font-size: 0.9rem;

    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .excerpt {
    color: var(--text-light);
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  button {
    background-color: var(--primary-color);
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: var(--border-radius);
    transition: var(--transition);

    &:hover {
      background-color: #2980b9;
    }
  }
`

const Sidebar = styled.aside`
  @media (max-width: 1024px) {
    order: -1;
  }
`

const SidebarSection = styled.div`
  background: white;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: var(--box-shadow);

  h3 {
    color: var(--text-color);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--primary-color);
  }
`

const CategoryList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.5rem;

    a {
      color: var(--text-light);
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      transition: var(--transition);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
`

const PopularPostsList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    a {
      color: var(--text-color);
      text-decoration: none;
      transition: var(--transition);

      &:hover {
        color: var(--primary-color);
      }
    }

    .date {
      color: var(--text-light);
      font-size: 0.9rem;
      margin-top: 0.25rem;
    }
  }
`

const articles = [
  {
    id: 1,
    title: 'Signature d\'un accord majeur pour la formation professionnelle',
    date: '30 Décembre 2023',
    author: 'Marie Dubois',
    category: 'Éducation',
    image: '/images/formation-pro.jpg',
    excerpt: 'Un nouvel accord entre la France et le Maroc vise à renforcer la coopération dans le domaine de la formation professionnelle...'
  },
  {
    id: 2,
    title: 'Festival des Arts Franco-Marocains : Un succès retentissant',
    date: '28 Décembre 2023',
    author: 'Ahmed Benjelloun',
    category: 'Culture',
    image: '/images/festival-arts.jpg',
    excerpt: 'La première édition du Festival des Arts Franco-Marocains a attiré plus de 10,000 visiteurs à Paris...'
  },
  {
    id: 3,
    title: 'Nouveau partenariat technologique entre startups',
    date: '25 Décembre 2023',
    author: 'Sophie Martin',
    category: 'Technologie',
    image: '/images/tech-partnership.jpg',
    excerpt: 'Cinq startups françaises et marocaines s\'associent pour développer des solutions innovantes...'
  }
]

const categories = [
  { name: 'Éducation', count: 12 },
  { name: 'Culture', count: 8 },
  { name: 'Économie', count: 15 },
  { name: 'Technologie', count: 6 },
  { name: 'Société', count: 10 }
]

const popularPosts = [
  {
    title: 'Les opportunités d\'investissement dans le secteur des énergies renouvelables',
    date: '22 Décembre 2023'
  },
  {
    title: 'Success story : De Casablanca à Paris, parcours d\'un entrepreneur',
    date: '20 Décembre 2023'
  },
  {
    title: 'Nouveau programme d\'échange universitaire lancé',
    date: '18 Décembre 2023'
  }
]

function News() {
  return (
    <NewsContainer>
      <NewsContent>
        <Header>
          <h1>Actualités</h1>
          <p>
            Restez informé des dernières nouvelles et développements concernant
            les relations franco-marocaines dans tous les domaines.
          </p>
        </Header>

        <ArticlesSection>
          {articles.map(article => (
            <ArticleCard key={article.id}>
              <img src={article.image} alt={article.title} />
              <div className="content">
                <h2>{article.title}</h2>
                <div className="meta">
                  <span>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    {article.date}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faUser} />
                    {article.author}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faTag} />
                    {article.category}
                  </span>
                </div>
                <p className="excerpt">{article.excerpt}</p>
                <button>Lire la suite</button>
              </div>
            </ArticleCard>
          ))}
        </ArticlesSection>

        <Sidebar>
          <SidebarSection>
            <h3>Catégories</h3>
            <CategoryList>
              {categories.map((category, index) => (
                <li key={index}>
                  <a href="#">
                    <span>{category.name}</span>
                    <span>({category.count})</span>
                  </a>
                </li>
              ))}
            </CategoryList>
          </SidebarSection>

          <SidebarSection>
            <h3>Articles Populaires</h3>
            <PopularPostsList>
              {popularPosts.map((post, index) => (
                <li key={index}>
                  <a href="#">{post.title}</a>
                  <div className="date">
                    <FontAwesomeIcon icon={faCalendarAlt} /> {post.date}
                  </div>
                </li>
              ))}
            </PopularPostsList>
          </SidebarSection>
        </Sidebar>
      </NewsContent>
    </NewsContainer>
  )
}

export default News
