import { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faHandshake, faChartLine, faIndustry } from '@fortawesome/free-solid-svg-icons'

const OpportunitiesContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
`

const OpportunitiesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
  }
`

const SectorFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
`

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  background-color: ${props => props.active ? 'var(--primary-color)' : 'white'};
  color: ${props => props.active ? 'white' : 'var(--text-color)'};
  transition: var(--transition);

  &:hover {
    background-color: ${props => props.active ? '#2980b9' : '#f8f9fa'};
  }
`

const OpportunitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`

const OpportunityCard = styled.div`
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);

  &:hover {
    transform: translateY(-5px);
  }

  .header {
    background-color: var(--primary-color);
    color: white;
    padding: 1rem;
    text-align: center;
  }

  .content {
    padding: 1.5rem;
  }

  h3 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  .sector {
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .description {
    color: var(--text-light);
    margin-bottom: 1rem;
  }

  .details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;

    p {
      color: var(--text-light);
      margin-bottom: 0.5rem;
    }
  }

  button {
    width: 100%;
    padding: 0.8rem;
    background-color: var(--primary-color);
    color: white;
    border-radius: var(--border-radius);
    margin-top: 1rem;
    transition: var(--transition);

    &:hover {
      background-color: #2980b9;
    }
  }
`

const opportunities = [
  {
    id: 1,
    title: 'Développement Énergies Renouvelables',
    sector: 'Énergie',
    icon: faIndustry,
    description: 'Recherche de partenaires pour le développement de projets solaires et éoliens au Maroc.',
    investment: '5-10M€',
    duration: '3-5 ans',
    location: 'Maroc (Multiple régions)'
  },
  {
    id: 2,
    title: 'Expansion Réseau Distribution',
    sector: 'Commerce',
    icon: faBuilding,
    description: 'Opportunité de franchise pour une chaîne de distribution française au Maroc.',
    investment: '2-5M€',
    duration: '2-3 ans',
    location: 'Casablanca, Rabat, Marrakech'
  },
  {
    id: 3,
    title: 'Innovation Agro-alimentaire',
    sector: 'Agriculture',
    icon: faHandshake,
    description: 'Projet de collaboration pour le développement de produits bio-innovants.',
    investment: '1-3M€',
    duration: '2 ans',
    location: 'France et Maroc'
  },
  {
    id: 4,
    title: 'Technologie Financière',
    sector: 'Finance',
    icon: faChartLine,
    description: 'Développement de solutions FinTech pour le marché franco-marocain.',
    investment: '3-7M€',
    duration: '2-4 ans',
    location: 'Paris, Casablanca'
  }
]

const sectors = ['Tous', 'Énergie', 'Commerce', 'Agriculture', 'Finance']

function EconomicOpportunities() {
  const [selectedSector, setSelectedSector] = useState('Tous')

  const filteredOpportunities = selectedSector === 'Tous'
    ? opportunities
    : opportunities.filter(opp => opp.sector === selectedSector)

  return (
    <OpportunitiesContainer>
      <OpportunitiesContent>
        <Header>
          <h1>Opportunités Économiques</h1>
          <p>
            Découvrez les opportunités d'investissement et de partenariat entre
            la France et le Maroc. Notre plateforme facilite la mise en relation
            entre les acteurs économiques des deux pays.
          </p>
        </Header>

        <SectorFilters>
          {sectors.map(sector => (
            <FilterButton
              key={sector}
              active={selectedSector === sector}
              onClick={() => setSelectedSector(sector)}
            >
              {sector}
            </FilterButton>
          ))}
        </SectorFilters>

        <OpportunitiesGrid>
          {filteredOpportunities.map(opportunity => (
            <OpportunityCard key={opportunity.id}>
              <div className="header">
                <h3>{opportunity.title}</h3>
              </div>
              <div className="content">
                <div className="sector">
                  <FontAwesomeIcon icon={opportunity.icon} />
                  <span>{opportunity.sector}</span>
                </div>
                <p className="description">{opportunity.description}</p>
                <div className="details">
                  <p><strong>Investissement requis :</strong> {opportunity.investment}</p>
                  <p><strong>Durée du projet :</strong> {opportunity.duration}</p>
                  <p><strong>Localisation :</strong> {opportunity.location}</p>
                </div>
                <button>Contacter</button>
              </div>
            </OpportunityCard>
          ))}
        </OpportunitiesGrid>
      </OpportunitiesContent>
    </OpportunitiesContainer>
  )
}

export default EconomicOpportunities
