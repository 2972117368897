import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faBook, faUniversity, faGlobe } from '@fortawesome/free-solid-svg-icons'

const EducationContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
`

const EducationContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
  }
`

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`

const ProgramCard = styled.div`
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);

  &:hover {
    transform: translateY(-5px);
  }

  .header {
    background-color: var(--primary-color);
    color: white;
    padding: 1.5rem;
    text-align: center;

    svg {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    h3 {
      margin: 0;
    }
  }

  .content {
    padding: 1.5rem;

    p {
      color: var(--text-light);
      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        color: var(--text-light);
        margin-bottom: 0.5rem;
        padding-left: 1.5rem;
        position: relative;

        &:before {
          content: "•";
          color: var(--primary-color);
          position: absolute;
          left: 0;
        }
      }
    }

    button {
      width: 100%;
      padding: 0.8rem;
      background-color: var(--primary-color);
      color: white;
      border-radius: var(--border-radius);
      margin-top: 1rem;
      transition: var(--transition);

      &:hover {
        background-color: #2980b9;
      }
    }
  }
`

const ResourcesSection = styled.section`
  margin-top: 4rem;

  h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-color);
  }
`

const ResourcesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`

const ResourceCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;

      a {
        color: var(--text-light);
        text-decoration: none;
        transition: var(--transition);

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
`

const programs = [
  {
    icon: faGraduationCap,
    title: 'Etudes Superieures',
    description: 'Programmes d\'echange universitaire entre la France et le Maroc',
    features: [
      'Double diplome',
      'Bourses d\'etudes',
      'Accompagnement administratif',
      'Cours de langue'
    ]
  },
  {
    icon: faBook,
    title: 'Formation Professionnelle',
    description: 'Formations certifiantes et stages professionnels',
    features: [
      'Stages en entreprise',
      'Certifications professionnelles',
      'Mentorat',
      'Reseautage professionnel'
    ]
  },
  {
    icon: faUniversity,
    title: 'Recherche Academique',
    description: 'Collaborations de recherche entre institutions',
    features: [
      'Projets de recherche conjoints',
      'Echange de chercheurs',
      'Publications communes',
      'Conferences internationales'
    ]
  }
]

const resources = [
  {
    title: 'Guides et Documentation',
    icon: faBook,
    links: [
      { text: 'Guide des etudes en France', url: '#' },
      { text: 'Systeme educatif marocain', url: '#' },
      { text: 'Procedures d\'inscription', url: '#' },
      { text: 'Financement des etudes', url: '#' }
    ]
  },
  {
    title: 'Liens Utiles',
    icon: faGlobe,
    links: [
      { text: 'Campus France Maroc', url: '#' },
      { text: 'Ministere de l\'Education', url: '#' },
      { text: 'Ambassade de France', url: '#' },
      { text: 'Bourses d\'etudes', url: '#' }
    ]
  }
]

function Education() {
  return (
    <EducationContainer>
      <EducationContent>
        <Header>
          <h1>Education et Formation</h1>
          <p>
            Decouvrez les opportunites d\'etudes et de formation entre la France
            et le Maroc. Notre plateforme facilite l\'acces aux programmes
            d\'echange et aux ressources educatives.
          </p>
        </Header>

        <ProgramsGrid>
          {programs.map((program, index) => (
            <ProgramCard key={index}>
              <div className="header">
                <FontAwesomeIcon icon={program.icon} />
                <h3>{program.title}</h3>
              </div>
              <div className="content">
                <p>{program.description}</p>
                <ul>
                  {program.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                <button>En savoir plus</button>
              </div>
            </ProgramCard>
          ))}
        </ProgramsGrid>

        <ResourcesSection>
          <h2>Ressources Educatives</h2>
          <ResourcesGrid>
            {resources.map((resource, index) => (
              <ResourceCard key={index}>
                <h3>
                  <FontAwesomeIcon icon={resource.icon} />
                  {resource.title}
                </h3>
                <ul>
                  {resource.links.map((link, idx) => (
                    <li key={idx}>
                      <a href={link.url}>{link.text}</a>
                    </li>
                  ))}
                </ul>
              </ResourceCard>
            ))}
          </ResourcesGrid>
        </ResourcesSection>
      </EducationContent>
    </EducationContainer>
  )
}

export default Education
