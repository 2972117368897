import styled from 'styled-components'
import ContactForm from '../components/ContactForm'

const ContactContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
  min-height: 100vh;
`

const ContactContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const ContactHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 600px;
    margin: 0 auto;
  }
`

function Contact() {
  return (
    <ContactContainer>
      <ContactContent>
        <ContactHeader>
          <h1>Contactez-nous</h1>
          <p>
            Vous avez des questions ou des suggestions ? N'hésitez pas à nous contacter.
            Notre équipe vous répondra dans les plus brefs délais.
          </p>
        </ContactHeader>
        <ContactForm />
      </ContactContent>
    </ContactContainer>
  )
}

export default Contact
