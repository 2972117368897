import styled from 'styled-components'

const AboutContainer = styled.div`
  padding: 6rem 2rem 4rem;
  background-color: var(--background-color);
`

const AboutContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const AboutHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    max-width: 800px;
    margin: 0 auto;
  }
`

const AboutSection = styled.section`
  margin-bottom: 4rem;

  h2 {
    color: var(--text-color);
    margin-bottom: 2rem;
  }

  p {
    color: var(--text-light);
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }
`

const MissionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const MissionCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
`

function About() {
  return (
    <AboutContainer>
      <AboutContent>
        <AboutHeader>
          <h1>À Propos de France-Maroc.fr</h1>
          <p>
            Découvrez notre mission de renforcement des liens entre la France et le Maroc
            à travers les échanges culturels, économiques et éducatifs.
          </p>
        </AboutHeader>

        <AboutSection>
          <h2>Notre Histoire</h2>
          <p>
            France-Maroc.fr est né de la volonté de créer une plateforme dynamique
            facilitant les échanges et la collaboration entre la France et le Maroc.
            Notre initiative vise à renforcer les liens historiques entre ces deux
            pays en favorisant les opportunités de partenariat dans divers domaines.
          </p>
          <p>
            Depuis notre création, nous nous efforçons de construire des ponts
            entre les communautés, les entreprises et les institutions des deux pays,
            contribuant ainsi à l'enrichissement mutuel de nos cultures et au
            développement de projets communs.
          </p>
        </AboutSection>

        <AboutSection>
          <h2>Notre Mission</h2>
          <MissionGrid>
            <MissionCard>
              <h3>Échanges Culturels</h3>
              <p>
                Promouvoir et faciliter les échanges culturels entre la France et
                le Maroc, enrichissant ainsi la compréhension mutuelle et le
                dialogue interculturel.
              </p>
            </MissionCard>

            <MissionCard>
              <h3>Développement Économique</h3>
              <p>
                Créer des opportunités de collaboration économique en mettant en
                relation les acteurs des deux pays et en facilitant les
                partenariats commerciaux.
              </p>
            </MissionCard>

            <MissionCard>
              <h3>Éducation et Formation</h3>
              <p>
                Soutenir les initiatives éducatives et les programmes de formation,
                permettant aux étudiants et professionnels d'enrichir leurs
                parcours à l'international.
              </p>
            </MissionCard>
          </MissionGrid>
        </AboutSection>

        <AboutSection>
          <h2>Notre Vision</h2>
          <p>
            Nous aspirons à devenir la référence incontournable pour tous ceux qui
            souhaitent développer des projets entre la France et le Maroc. Notre
            plateforme se veut un catalyseur d'opportunités, facilitant les
            connexions et les échanges entre les deux pays.
          </p>
          <p>
            À travers nos différentes initiatives et notre réseau grandissant,
            nous travaillons à créer un écosystème dynamique où les idées,
            les talents et les ressources peuvent se rencontrer et s'épanouir.
          </p>
        </AboutSection>
      </AboutContent>
    </AboutContainer>
  )
}

export default About
