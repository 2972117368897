import { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake, faChartLine, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import Hero from '../components/Hero'
import MoroccanCityGuide from '../components/MoroccanCityGuide'

const HomeContainer = styled.div`
  padding-top: 60px; // Pour compenser la navbar fixe
`

const FeaturesSection = styled.section`
  padding: 4rem 2rem;
  background-color: #f8f9fa;
`

const FeaturesGrid = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`

const FeatureCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .icon {
    font-size: 2.5rem;
    color: #3498db;
    margin-bottom: 1rem;
  }

  h3 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }

  p {
    color: #7f8c8d;
    line-height: 1.6;
  }
`

const EventsSection = styled.section`
  padding: 4rem 2rem;
  background-color: white;
`

const EventsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const SectionTitle = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
`

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`

const EventCard = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .content {
    padding: 1.5rem;
  }

  h4 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }

  p {
    color: #7f8c8d;
    margin-bottom: 1rem;
  }

  .date {
    color: #3498db;
    font-weight: 500;
  }
`

function Home() {
  useEffect(() => {
    document.title = "France-Maroc.fr - Plateforme d'Échange et de Collaboration France-Maroc"
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Bienvenue sur France-Maroc.fr, votre portail en ligne pour les échanges culturels, économiques et éducatifs entre la France et le Maroc. Découvrez des opportunités, des événements, des ressources et connectez-vous avec la communauté franco-marocaine.')
    }
  }, [])

  return (
    <HomeContainer>
      <Hero />
      
      <FeaturesSection id="initiatives">
        <FeaturesGrid>
          <FeatureCard>
            <FontAwesomeIcon icon={faHandshake} className="icon" />
            <h3>Échanges Culturels</h3>
            <p>Découvrez les événements et programmes culturels qui rapprochent nos deux pays. Participez à des échanges enrichissants et construisez des ponts entre nos cultures.</p>
          </FeatureCard>
          
          <FeatureCard>
            <FontAwesomeIcon icon={faChartLine} className="icon" />
            <h3>Opportunités Économiques</h3>
            <p>Explorez les opportunités d'affaires et de partenariats entre la France et le Maroc. Développez votre réseau et créez des synergies durables.</p>
          </FeatureCard>
          
          <FeatureCard>
            <FontAwesomeIcon icon={faGraduationCap} className="icon" />
            <h3>Éducation et Formation</h3>
            <p>Accédez aux programmes éducatifs et aux échanges académiques. Développez vos compétences et élargissez vos horizons.</p>
          </FeatureCard>
        </FeaturesGrid>
      </FeaturesSection>

      <EventsSection>
        <EventsContainer>
          <SectionTitle>Événements à Venir</SectionTitle>
          <EventsGrid>
            <EventCard>
              <img src="/images/forum-economique.jpg" alt="Forum économique" />
              <div className="content">
                <h4>Forum Économique France-Maroc</h4>
                <p>Rencontrez les acteurs économiques majeurs des deux pays et découvrez de nouvelles opportunités de collaboration.</p>
                <span className="date">15 Mars 2024</span>
              </div>
            </EventCard>

            <EventCard>
              <img src="/images/festival-culturel.jpg" alt="Festival culturel" />
              <div className="content">
                <h4>Festival des Cultures Croisées</h4>
                <p>Une célébration de la richesse culturelle franco-marocaine à travers l'art, la musique et la gastronomie.</p>
                <span className="date">22 Avril 2024</span>
              </div>
            </EventCard>

            <EventCard>
              <img src="/images/conference-education.jpg" alt="Conférence éducation" />
              <div className="content">
                <h4>Conférence sur l'Éducation</h4>
                <p>Échanges sur les opportunités d'études et les programmes de formation entre la France et le Maroc.</p>
                <span className="date">10 Mai 2024</span>
              </div>
            </EventCard>
          </EventsGrid>
        </EventsContainer>
      </EventsSection>

      <MoroccanCityGuide />
    </HomeContainer>
  )
}

export default Home
