import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faClock, 
  faTag, 
  faInfoCircle, 
  faRoute 
} from '@fortawesome/free-solid-svg-icons';

// Styles
const TourismContainer = styled.div`
  padding: 2rem;
  background-color: #f5f5f5;
  min-height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const TourismContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
  position: relative;
  z-index: 1;
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  color: #333;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    max-width: 800px;
    margin: 0 auto;
    color: #666;
    line-height: 1.6;
  }
`

const CitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  width: 100%;
`

const CityCard = styled.div`
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .content {
    padding: 1.5rem;

    h3 {
      color: #333;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      color: #666;

      svg {
        margin-right: 0.5rem;
        color: #4a90e2;
      }
    }

    .description {
      color: #666;
      margin: 1rem 0;
      line-height: 1.6;
    }

    button {
      background-color: #4a90e2;
      color: white;
      border: none;
      padding: 0.8rem 1.5rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #357abd;
      }
    }
  }
`

const SectionTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`

const CircuitsSection = styled.section`
  margin-top: 4rem;
`

const CircuitCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h3 {
    color: #333;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    svg {
      margin-right: 1rem;
      color: #4a90e2;
    }
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    .detail-item {
      display: flex;
      align-items: center;
      color: #666;

      svg {
        margin-right: 0.5rem;
        color: #4a90e2;
      }
    }
  }

  .description {
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  h4 {
    color: #333;
    margin: 1.5rem 0 1rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      color: #666;
      margin-bottom: 0.5rem;
      padding-left: 1.5rem;
      position: relative;

      &:before {
        content: "•";
        color: #4a90e2;
        position: absolute;
        left: 0;
      }
    }
  }
`

const GuideCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h3 {
    color: #333;
    margin-bottom: 1rem;
  }

  .info {
    margin-bottom: 0.5rem;
    color: #666;
  }

  .langues {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    flex-wrap: wrap;
  }

  .langue {
    background: #4a90e2;
    color: white;
    padding: 0.3rem 0.8rem;
    border-radius: 15px;
    font-size: 0.9rem;
  }

  .regions {
    margin-top: 1rem;
  }
`

// Données
const tourismData = {
  cities: [
    {
      name: 'Marrakech',
      image: '/images/marrakech.jpg',
      region: 'Marrakech-Safi',
      bestTime: 'Mars à Mai, Septembre à Novembre',
      climate: 'Semi-aride, températures douces en hiver, chaudes en été',
      description: 'Surnommée la ville rouge, Marrakech est un mélange fascinant de passé et de présent. Ses souks animés, ses palais historiques et sa célèbre place Jemaa el-Fna en font une destination incontournable.',
      highlights: [
        'Place Jemaa el-Fna - Le cœur battant de la ville',
        'Jardin Majorelle - Un paradis bleu créé par Yves Saint Laurent',
        'Médina - Un labyrinthe de souks',
        'Palais Bahia - Chef d\'œuvre architectural',
        'Les Jardins de la Menara',
        'Palais El Badi',
        'Musée Yves Saint Laurent',
        'Tombeaux Saadiens',
        'Musée de Marrakech'
      ],
      activities: [
        'Cours de cuisine marocaine traditionnelle',
        'Tour en calèche au coucher du soleil',
        'Hammam et spa traditionnel',
        'Shopping dans les souks',
        'Excursion en montgolfière',
        'Atelier de poterie',
        'Dégustation de thés et pâtisseries',
        'Visite des tanneries',
        'Safari en quad dans la palmeraie'
      ],
      restaurants: [
        'La Grande Table Marocaine - Royal Mansour',
        'Nomad - Vue sur les souks',
        'Le Jardin - Restaurant dans un riad',
        'Al Fassia - Cuisine traditionnelle',
        'L\'Abeille - Cuisine fusion',
        'Dar Yacout - Expérience palatiale',
        'Chez Chegrouni - Authentique'
      ],
      hotels: [
        {
          name: 'Royal Mansour Marrakech',
          category: '5* Palace',
          description: 'Hôtel de luxe avec riads privés',
          price: 'À partir de 1000€/nuit'
        },
        {
          name: 'La Mamounia',
          category: '5* Palace',
          description: 'Hôtel légendaire',
          price: 'À partir de 600€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival du Film de Marrakech',
          period: 'Novembre-Décembre',
          description: 'Festival international'
        },
        {
          name: 'Festival des Arts Populaires',
          period: 'Juillet',
          description: 'Traditions folkloriques'
        }
      ],
      shopping: [
        {
          area: 'Souk Semmarine',
          speciality: 'Artisanat, bijoux, textiles',
          tips: 'Négociation recommandée'
        },
        {
          area: 'Gueliz',
          speciality: 'Boutiques modernes',
          tips: 'Prix fixes'
        }
      ]
    },
    {
      name: 'Fès',
      image: '/images/fes.jpg',
      region: 'Fès-Meknès',
      bestTime: 'Mars à Mai, Septembre à Novembre',
      climate: 'Continental méditerranéen',
      description: 'La capitale spirituelle et culturelle du Maroc, avec sa médina millénaire classée UNESCO.',
      highlights: [
        'Médina de Fès - Plus grande zone piétonne au monde',
        'Bab Boujloud - Porte bleue emblématique',
        'Medersa Bou Inania - Chef d\'œuvre architectural',
        'Université Al Quaraouiyine - Plus ancienne au monde',
        'Tanneries Chouara - Spectacle unique',
        'Place Seffarine - Artisans du cuivre',
        'Dar Batha - Musée des arts et traditions'
      ],
      activities: [
        'Visite guidée de la médina',
        'Atelier de poterie traditionnelle',
        'Cours de cuisine fassie',
        'Visite des tanneries',
        'Shopping dans les souks',
        'Dégustation de pâtisseries locales',
        'Tour des artisans'
      ],
      restaurants: [
        'Nur Restaurant - Gastronomie moderne',
        'Café Clock - Fusion contemporaine',
        'Restaurant Dar Roumana',
        'La Maison Bleue',
        'Riad Fès - Restaurant gourmet'
      ],
      hotels: [
        {
          name: 'Riad Fès',
          category: '5*',
          description: 'Luxe traditionnel',
          price: 'À partir de 400€/nuit'
        },
        {
          name: 'Palais Faraj',
          category: '5*',
          description: 'Vue panoramique',
          price: 'À partir de 300€/nuit'
        }
      ]
    },
    {
      name: 'Tanger',
      image: '/images/tanger.jpg',
      region: 'Tanger-Tétouan-Al Hoceïma',
      bestTime: 'Avril à Octobre',
      climate: 'Méditerranéen',
      description: 'Porte de l\'Afrique, ville cosmopolite au charme légendaire.',
      highlights: [
        'Kasbah - Vue sur le détroit',
        'Grottes d\'Hercule',
        'Médina historique',
        'Cap Spartel',
        'Grand Socco',
        'Petit Socco',
        'Musée de la Kasbah'
      ],
      activities: [
        'Visite des cafés historiques',
        'Tour en bateau',
        'Excursion aux grottes',
        'Photographie dans la médina',
        'Observation des dauphins'
      ],
      restaurants: [
        'El Morocco Club',
        'Saveur de Poisson',
        'Café Hafa',
        'Le Salon Bleu',
        'Anna e Paolo'
      ],
      hotels: [
        {
          name: 'Nord Pinus Tanger',
          category: '5*',
          description: 'Vue sur le détroit',
          price: 'À partir de 200€/nuit'
        }
      ]
    },
    {
      name: 'Chefchaouen',
      image: '/images/chefchaouen.jpg',
      region: 'Tanger-Tétouan-Al Hoceïma',
      bestTime: 'Mars à Juin, Septembre à Novembre',
      climate: 'Méditerranéen montagnard',
      description: 'Surnommée la ville bleue, Chefchaouen est nichée dans les montagnes du Rif. Ses ruelles peintes en bleu et son ambiance paisible en font une destination unique.',
      highlights: [
        'Médina bleue - Ruelles et escaliers colorés',
        'Place Outa el Hammam - Centre historique',
        'Kasbah - Musée et jardin andalou',
        'Source Ras el Ma - Cascade naturelle',
        'Grande Mosquée - Architecture rifaine',
        'Parc National de Talassemtane',
        'Point de vue de la mosquée espagnole'
      ],
      activities: [
        'Randonnée dans le Rif',
        'Photographie dans les ruelles bleues',
        'Shopping d\'artisanat local',
        'Dégustation de cuisine rifaine',
        'Visite des ateliers de tissage',
        'Excursion aux cascades d\'Akchour',
        'Cours de peinture traditionnelle'
      ],
      restaurants: [
        'Bab Ssour - Cuisine traditionnelle',
        'Beldi Bab Ssour - Vue panoramique',
        'Casa Hassan - Gastronomie raffinée',
        'Café Clock - Fusion moderne',
        'Restaurant Populaire - Authentique'
      ],
      hotels: [
        {
          name: 'Lina Ryad & Spa',
          category: '5*',
          description: 'Riad luxueux avec spa',
          price: 'À partir de 180€/nuit'
        },
        {
          name: 'Dar Echchaouen',
          category: '4*',
          description: 'Vue panoramique sur les montagnes',
          price: 'À partir de 120€/nuit'
        }
      ]
    },
    {
      name: 'Essaouira',
      image: '/images/essaouira.jpg',
      region: 'Marrakech-Safi',
      bestTime: 'Avril à Octobre',
      climate: 'Océanique tempéré',
      description: 'Ancienne Mogador, cette cité fortifiée en bord d\'océan est connue pour ses remparts, son port de pêche pittoresque et sa scène artistique vibrante.',
      highlights: [
        'Médina - Patrimoine UNESCO',
        'Port de pêche traditionnel',
        'Skala du Port - Fortifications',
        'Plage d\'Essaouira - Sports nautiques',
        'Île de Mogador - Réserve naturelle',
        'Souks artisanaux',
        'Quartier des artistes'
      ],
      activities: [
        'Kitesurf et windsurf',
        'Cours de cuisine de poisson',
        'Visite des galeries d\'art',
        'Promenade en chameau sur la plage',
        'Shopping d\'artisanat thuya',
        'Observation des oiseaux',
        'Excursion en bateau'
      ],
      restaurants: [
        'L\'Heure Bleue - Gastronomique',
        'La Table by Madada - Fruits de mer',
        'Taros - Vue panoramique',
        'La Chèvre d\'Or - Cuisine française',
        'Ocean Vagabond - Beach restaurant'
      ],
      hotels: [
        {
          name: 'L\'Heure Bleue Palais',
          category: '5*',
          description: 'Palais historique rénové',
          price: 'À partir de 250€/nuit'
        },
        {
          name: 'Riad Dar Maya',
          category: '4*',
          description: 'Riad élégant dans la médina',
          price: 'À partir de 150€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival Gnaoua',
          period: 'Juin',
          description: 'Festival de musique world'
        },
        {
          name: 'Festival des Alizés',
          period: 'Avril',
          description: 'Musique classique'
        }
      ]
    },
    {
      name: 'Ouarzazate',
      image: '/images/ouarzazate.jpg',
      region: 'Drâa-Tafilalet',
      bestTime: 'Octobre à Avril',
      climate: 'Désertique',
      description: 'La porte du désert, célèbre pour ses studios de cinéma et ses kasbahs majestueuses, dont la célèbre Aït Benhaddou.',
      highlights: [
        'Kasbah Aït Benhaddou - Site UNESCO',
        'Studios Atlas - Cinéma',
        'Kasbah Taourirt',
        'Musée du Cinéma',
        'Oasis de Fint',
        'Vallée du Drâa',
        'Barrage El Mansour Eddahbi'
      ],
      activities: [
        'Visite des studios de cinéma',
        'Tour en quad dans le désert',
        'Excursion aux kasbahs',
        'Randonnée dans les oasis',
        'Observation des étoiles',
        'Visite des villages berbères',
        'Photo safari'
      ],
      restaurants: [
        'La Kasbah des Sables',
        'Douyria - Cuisine traditionnelle',
        'Le Relais de Saint-Exupéry',
        'Restaurant Kasbah Taourirt',
        'Les Jardins de Ouarzazate'
      ],
      hotels: [
        {
          name: 'Berbère Palace',
          category: '5*',
          description: 'Hôtel historique du cinéma',
          price: 'À partir de 200€/nuit'
        },
        {
          name: 'Ksar Ighnda',
          category: '4*',
          description: 'Kasbah de luxe',
          price: 'À partir de 150€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival du Cinéma Africain',
          period: 'Mars',
          description: 'Célébration du cinéma africain'
        }
      ]
    },
    {
      name: 'Rabat',
      image: '/images/rabat.jpg',
      region: 'Rabat-Salé-Kénitra',
      bestTime: 'Mars à Juin, Septembre à Novembre',
      climate: 'Méditerranéen tempéré',
      description: 'Capitale du Maroc, Rabat allie histoire millénaire et modernité avec ses monuments historiques et ses quartiers contemporains.',
      highlights: [
        'Tour Hassan - Minaret historique',
        'Mausolée Mohammed V',
        'Kasbah des Oudayas',
        'Chellah - Site romain et mérinide',
        'Médina de Rabat',
        'Musée Mohammed VI',
        'Plage de Rabat'
      ],
      activities: [
        'Visite des monuments historiques',
        'Promenade dans les jardins andalous',
        'Shopping moderne',
        'Surf à la plage',
        'Visite des galeries d\'art',
        'Golf',
        'Balade en bateau sur le Bouregreg'
      ],
      restaurants: [
        'Le Dhow - Restaurant flottant',
        'Dar Naji - Cuisine marocaine',
        'Le Grand Comptoir - Brasserie',
        'La Maison du Couscous',
        'L\'Océan - Fruits de mer'
      ],
      hotels: [
        {
          name: 'Sofitel Jardin des Roses',
          category: '5*',
          description: 'Luxe contemporain',
          price: 'À partir de 220€/nuit'
        },
        {
          name: 'Riad Dar El Kebira',
          category: '4*',
          description: 'Riad dans la médina',
          price: 'À partir de 140€/nuit'
        }
      ],
      events: [
        {
          name: 'Mawazine',
          period: 'Juin',
          description: 'Festival de musique international'
        },
        {
          name: 'Festival du Film de Rabat',
          period: 'Novembre',
          description: 'Cinéma international'
        }
      ]
    },
    {
      name: 'Meknès',
      image: '/images/meknes.jpg',
      region: 'Fès-Meknès',
      bestTime: 'Mars à Mai, Septembre à Novembre',
      climate: 'Continental méditerranéen',
      description: 'Ancienne capitale impériale, Meknès est connue pour ses monuments grandioses et son patrimoine historique ismaïlien.',
      highlights: [
        'Bab Mansour - Plus grande porte d\'Afrique',
        'Place El Hedim - Cœur de la ville',
        'Mausolée Moulay Ismail',
        'Grenier Heri es-Souani',
        'Médina de Meknès - UNESCO',
        'Musée Dar Jamai',
        'Ruines romaines de Volubilis'
      ],
      activities: [
        'Visite des monuments historiques',
        'Shopping dans les souks',
        'Excursion à Volubilis',
        'Dégustation de vins locaux',
        'Tour des remparts',
        'Visite des caves traditionnelles',
        'Cours de cuisine traditionnelle'
      ],
      restaurants: [
        'Riad Lahboul - Gastronomie raffinée',
        'Restaurant Mille et Une Nuits',
        'Le Collier de la Colombe',
        'La Virgule - Cuisine française',
        'Restaurant Oumnia - Local'
      ],
      hotels: [
        {
          name: 'Riad D\'Or',
          category: '5*',
          description: 'Riad historique restauré',
          price: 'À partir de 150€/nuit'
        },
        {
          name: 'Zaki Hotel',
          category: '4*',
          description: 'Vue sur la médina',
          price: 'À partir de 100€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival de Volubilis',
          period: 'Juillet',
          description: 'Arts et culture'
        }
      ]
    },
    {
      name: 'Agadir',
      image: '/images/agadir.jpg',
      region: 'Souss-Massa',
      bestTime: 'Toute l\'année',
      climate: 'Océanique doux',
      description: 'Station balnéaire moderne offrant 300 jours de soleil par an, avec sa magnifique baie et ses infrastructures touristiques de qualité.',
      highlights: [
        'Plage d\'Agadir - 10 km de sable fin',
        'Marina d\'Agadir',
        'Kasbah d\'Agadir Oufella',
        'Souk El Had',
        'Vallée des Oiseaux',
        'Crocoparc',
        'Port de pêche'
      ],
      activities: [
        'Sports nautiques',
        'Golf',
        'Surf et bodyboard',
        'Spa et thalassothérapie',
        'Excursions dans l\'Anti-Atlas',
        'Parapente',
        'Shopping moderne'
      ],
      restaurants: [
        'Pure Passion - Gastronomique',
        'Le Petit Pêcheur - Fruits de mer',
        'L\'Adress - Cuisine internationale',
        'La Scala - Vue marina',
        'Le Jardin d\'Eau - Marocain'
      ],
      hotels: [
        {
          name: 'Sofitel Agadir Royal Bay',
          category: '5*',
          description: 'Resort de luxe en bord de mer',
          price: 'À partir de 250€/nuit'
        },
        {
          name: 'Hyatt Place Agadir',
          category: '4*',
          description: 'Hôtel moderne',
          price: 'À partir de 150€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival Timitar',
          period: 'Juillet',
          description: 'Musiques du monde'
        }
      ]
    },
    {
      name: 'Asilah',
      image: '/images/asilah.jpg',
      region: 'Tanger-Tétouan-Al Hoceïma',
      bestTime: 'Mai à Septembre',
      climate: 'Méditerranéen',
      description: 'Petite ville côtière aux murs blancs, connue pour son festival d\'art et ses plages pittoresques.',
      highlights: [
        'Médina blanche',
        'Remparts portugais',
        'Palais de Raissouli',
        'Plage Paradise',
        'Port de pêche',
        'Centre culturel Hassan II',
        'Galeries d\'art'
      ],
      activities: [
        'Balade dans la médina',
        'Observation des fresques murales',
        'Plage et sports nautiques',
        'Pêche traditionnelle',
        'Festivals culturels',
        'Shopping artisanal',
        'Photographie'
      ],
      restaurants: [
        'Casa Pepe - Fruits de mer',
        'Restaurant Port XIV',
        'Casa Garcia - Méditerranéen',
        'La Place - Vue mer',
        'Dar Al Maghribia'
      ],
      hotels: [
        {
          name: 'Christina\'s House',
          category: '4*',
          description: 'Maison d\'hôtes de charme',
          price: 'À partir de 120€/nuit'
        },
        {
          name: 'Hotel Al Alba',
          category: '3*',
          description: 'Vue sur l\'océan',
          price: 'À partir de 80€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival International d\'Art',
          period: 'Août',
          description: 'Art mural et expositions'
        }
      ]
    },
    {
      name: 'El Jadida',
      image: '/images/eljadida.jpg',
      region: 'Casablanca-Settat',
      bestTime: 'Avril à Octobre',
      climate: 'Océanique méditerranéen',
      description: 'Cité portugaise classée UNESCO, El Jadida charme par son patrimoine historique et ses plages.',
      highlights: [
        'Cité portugaise - UNESCO',
        'Citerne portugaise',
        'Plage d\'El Jadida',
        'Remparts et bastions',
        'Port de pêche',
        'Phare de Sidi Mesbah',
        'Souk hebdomadaire'
      ],
      activities: [
        'Visite de la cité portugaise',
        'Sports nautiques',
        'Golf',
        'Équitation sur la plage',
        'Pêche sportive',
        'Hammam traditionnel',
        'Shopping au souk'
      ],
      restaurants: [
        'L\'Iglesia - Dans la cité portugaise',
        'La Portugaise - Fruits de mer',
        'Restaurant Riad',
        'Le Privé - Vue mer',
        'L\'Oriental - Cuisine marocaine'
      ],
      hotels: [
        {
          name: 'Mazagan Beach Resort',
          category: '5*',
          description: 'Resort luxueux',
          price: 'À partir de 280€/nuit'
        },
        {
          name: 'Riad El Jadida',
          category: '4*',
          description: 'Dans la cité portugaise',
          price: 'À partir de 100€/nuit'
        }
      ],
      events: [
        {
          name: 'Moussem Moulay Abdellah Amghar',
          period: 'Août',
          description: 'Festival culturel et religieux'
        }
      ]
    },
    {
      name: 'Ifrane',
      image: '/images/ifrane.jpg',
      region: 'Fès-Meknès',
      bestTime: 'Toute l\'année (ski en hiver)',
      climate: 'Continental montagnard',
      description: 'Surnommée la petite Suisse du Maroc, Ifrane est une station de montagne avec son architecture européenne et ses paysages alpins.',
      highlights: [
        'Parc national d\'Ifrane',
        'Statue du Lion',
        'Lac Daït Aoua',
        'Forêt de cèdres',
        'Station de ski Michlifen',
        'Université Al Akhawayn',
        'Jardins européens'
      ],
      activities: [
        'Ski en hiver',
        'Randonnée',
        'Observation des singes magots',
        'VTT en montagne',
        'Pique-nique en forêt',
        'Golf d\'altitude',
        'Photographie nature'
      ],
      restaurants: [
        'L\'Écureuil - Cuisine internationale',
        'La Paix - Gastronomique',
        'Restaurant de l\'Hôtel Chamonix',
        'Le Chalet - Spécialités montagnardes',
        'La Forêt - Vue sur le parc'
      ],
      hotels: [
        {
          name: 'Michlifen Resort & Golf',
          category: '5*',
          description: 'Resort de luxe alpin',
          price: 'À partir de 200€/nuit'
        },
        {
          name: 'Hôtel Chamonix',
          category: '4*',
          description: 'Style chalet de montagne',
          price: 'À partir de 120€/nuit'
        }
      ],
      events: [
        {
          name: 'Festival de la Neige',
          period: 'Janvier',
          description: 'Célébration hivernale'
        }
      ]
    }
  ],
  circuits: [
    {
      title: 'Circuit des Villes Impériales',
      duration: '8 jours',
      distance: '1200 km',
      difficulty: 'Facile',
      price: 'À partir de 900€',
      description: 'Un voyage fascinant à travers l\'histoire du Maroc en visitant ses quatre villes impériales.',
      highlights: [
        'Palais Royal de Rabat',
        'Médina de Fès',
        'Porte Bab Mansour de Meknès',
        'Jardins de la Menara de Marrakech'
      ],
      stops: [
        'Jour 1-2: Rabat',
        'Jour 3-4: Fès',
        'Jour 5: Meknès',
        'Jour 6-8: Marrakech'
      ],
      inclusions: [
        'Transport climatisé',
        'Hébergement en riads',
        'Guide francophone',
        'Petits déjeuners'
      ],
      hebergement: [
        'Riad à Rabat',
        'Riad à Fès',
        'Riad à Marrakech'
      ]
    },
    {
      title: 'Route du Désert',
      duration: '6 jours',
      distance: '800 km',
      difficulty: 'Modéré',
      price: 'À partir de 750€',
      description: 'Une aventure à travers l\'Atlas et le Sahara.',
      highlights: [
        'Kasbah Ait Benhaddou',
        'Gorges du Dadès',
        'Dunes de Merzouga',
        'Vallée du Drâa'
      ],
      stops: [
        'Jour 1: Marrakech - Ouarzazate',
        'Jour 2: Ouarzazate - Dadès',
        'Jour 3-4: Merzouga',
        'Jour 5-6: Retour Marrakech'
      ],
      inclusions: [
        'Transport 4x4',
        'Nuit dans le désert',
        'Balade en dromadaire',
        'Pension complète'
      ],
      hebergement: [
        'Riad à Ouarzazate',
        'Camp de luxe à Merzouga',
        'Kasbah dans les gorges'
      ]
    },
    {
      title: 'Tour Côte Atlantique',
      duration: '7 jours',
      distance: '900 km',
      difficulty: 'Facile',
      price: 'À partir de 800€',
      description: 'Découverte de la côte atlantique marocaine.',
      highlights: [
        'Mosquée Hassan II',
        'Cité portugaise d\'El Jadida',
        'Lagune d\'Oualidia',
        'Médina d\'Essaouira'
      ],
      stops: [
        'Jour 1-2: Casablanca',
        'Jour 3: El Jadida',
        'Jour 4: Oualidia',
        'Jour 5-7: Essaouira'
      ],
      inclusions: [
        'Transport confortable',
        'Hôtels en bord de mer',
        'Visites guidées',
        'Activités nautiques'
      ],
      hebergement: [
        'Sofitel Casablanca',
        'Mazagan Resort',
        'L\'Heure Bleue Essaouira'
      ]
    },
    {
      title: 'Aventure Montagne et Désert',
      duration: '10 jours',
      distance: '1200 km',
      difficulty: 'Modéré à Sportif',
      price: 'À partir de 1100€',
      description: 'Un circuit combinant les sommets de l\'Atlas et les dunes du désert, pour les amateurs d\'aventure et de paysages contrastés.',
      highlights: [
        'Sommets du Haut Atlas',
        'Vallée de l\'Ourika',
        'Gorges du Todra',
        'Dunes de Merzouga',
        'Oasis et palmeraies',
        'Villages berbères traditionnels',
        'Nuit sous les étoiles'
      ],
      stops: [
        'Jour 1: Marrakech - Accueil et briefing',
        'Jour 2-3: Randonnée dans l\'Atlas',
        'Jour 4: Vallée de l\'Ourika',
        'Jour 5: Gorges du Todra',
        'Jour 6-7: Désert de Merzouga',
        'Jour 8: Oasis et palmeraies',
        'Jour 9: Route des kasbahs',
        'Jour 10: Retour à Marrakech'
      ],
      inclusions: [
        'Transport 4x4 avec chauffeur-guide',
        'Hébergement varié (riads, bivouac, gîtes)',
        'Pension complète',
        'Équipement de camping',
        'Guide de montagne certifié',
        'Activités et excursions',
        'Transferts aéroport'
      ],
      hebergement: [
        'Riad de charme à Marrakech',
        'Gîte de montagne dans l\'Atlas',
        'Camp de luxe dans le désert',
        'Kasbah traditionnelle',
        'Maison d\'hôtes dans l\'oasis'
      ]
    },
    {
      title: 'Circuit Culturel et Artisanal',
      duration: '12 jours',
      distance: '1500 km',
      difficulty: 'Facile',
      price: 'À partir de 1300€',
      description: 'Un voyage à la découverte des traditions artisanales et culturelles du Maroc, rencontres avec les artisans et immersion dans le savoir-faire local.',
      highlights: [
        'Artisanat de Fès',
        'Poterie de Safi',
        'Tapis de Rabat',
        'Cuir de Marrakech',
        'Bois de thuya d\'Essaouira',
        'Bijoux berbères',
        'Art contemporain de Casablanca'
      ],
      stops: [
        'Jour 1-2: Casablanca - Art moderne',
        'Jour 3-4: Rabat - Tapis et broderie',
        'Jour 5-6: Fès - Artisanat traditionnel',
        'Jour 7: Meknès - Art du bois',
        'Jour 8-9: Marrakech - Cuir et métaux',
        'Jour 10: Safi - Poterie',
        'Jour 11-12: Essaouira - Bois et argenterie'
      ],
      inclusions: [
        'Transport privé climatisé',
        'Hébergement en riads artistiques',
        'Demi-pension gastronomique',
        'Ateliers avec artisans',
        'Guide culturel spécialisé',
        'Visites des coopératives',
        'Démonstrations artisanales'
      ],
      hebergement: [
        'Hôtel Art Palace Casablanca',
        'Riad des Artistes Fès',
        'Maison des Arts Marrakech',
        'Dar des Artisans Essaouira',
        'Design Hotel Rabat'
      ]
    }
  ],
  guides: [
    {
      name: 'Hassan',
      specialite: 'Histoire et Culture',
      langues: ['Français', 'Anglais', 'Arabe'],
      experience: '15 ans',
      regions: ['Marrakech', 'Atlas', 'Désert'],
      prix: 'À partir de 100€/jour'
    },
    {
      name: 'Fatima',
      specialite: 'Gastronomie et Artisanat',
      langues: ['Français', 'Espagnol', 'Arabe'],
      experience: '10 ans',
      regions: ['Fès', 'Meknès'],
      prix: 'À partir de 90€/jour'
    },
    {
      name: 'Karim',
      specialite: 'Aventure et Sport',
      langues: ['Anglais', 'Allemand', 'Arabe'],
      experience: '12 ans',
      regions: ['Atlas', 'Désert', 'Côte'],
      prix: 'À partir de 110€/jour'
    }
  ]
};

function Tourism() {
  return (
    <TourismContainer>
      <TourismContent>
        <Header>
          <h1>Tourisme au Maroc</h1>
          <p>
            Découvrez les trésors cachés du Maroc, des médinas ancestrales aux
            dunes dorées du Sahara. Notre guide vous présente les destinations
            incontournables et les circuits les plus fascinants.
          </p>
        </Header>

        <section>
          <SectionTitle>Villes à Visiter</SectionTitle>
          <CitiesGrid>
            {tourismData.cities.map((city, index) => (
              <CityCard key={index}>
                <img src={city.image} alt={city.name} />
                <div className="content">
                  <h3>{city.name}</h3>
                  <div className="info">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span>{city.region}</span>
                  </div>
                  <div className="info">
                    <FontAwesomeIcon icon={faClock} />
                    <span>Meilleure période: {city.bestTime}</span>
                  </div>
                  <p className="description">{city.description}</p>
                  
                  {city.climate && (
                    <div className="climate">
                      <strong>Climat:</strong>
                      <p>{city.climate}</p>
                    </div>
                  )}
                  
                  {city.highlights && (
                    <div className="highlights">
                      <strong>Points d'intérêt:</strong>
                      <ul>
                        {city.highlights.map((highlight, idx) => (
                          <li key={idx}>{highlight}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  {city.activities && (
                    <div className="activities">
                      <strong>Activités:</strong>
                      <ul>
                        {city.activities.map((activity, idx) => (
                          <li key={idx}>{activity}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  {city.restaurants && (
                    <div className="restaurants">
                      <strong>Restaurants:</strong>
                      <ul>
                        {city.restaurants.map((restaurant, idx) => (
                          <li key={idx}>{restaurant}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {city.hotels && (
                    <div className="hotels">
                      <strong>Hôtels:</strong>
                      <ul>
                        {city.hotels.map((hotel, idx) => (
                          <li key={idx}>
                            {hotel.name} - {hotel.category}
                            <br />
                            <small>{hotel.description}</small>
                            <br />
                            <small>{hotel.price}</small>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {city.events && (
                    <div className="events">
                      <strong>Événements:</strong>
                      <ul>
                        {city.events.map((event, idx) => (
                          <li key={idx}>
                            {event.name}
                            <br />
                            <small>{event.period} - {event.description}</small>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {city.shopping && (
                    <div className="shopping">
                      <strong>Shopping:</strong>
                      <ul>
                        {city.shopping.map((shop, idx) => (
                          <li key={idx}>
                            {shop.area}
                            <br />
                            <small>{shop.speciality}</small>
                            <br />
                            <small>Conseil: {shop.tips}</small>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  <button>En savoir plus</button>
                </div>
              </CityCard>
            ))}
          </CitiesGrid>
        </section>

        <CircuitsSection>
          <SectionTitle>Circuits Touristiques</SectionTitle>
          {tourismData.circuits.map((circuit, index) => (
            <CircuitCard key={index}>
              <h3>
                <FontAwesomeIcon icon={faRoute} />
                {circuit.title}
              </h3>
              <div className="details">
                <div className="detail-item">
                  <FontAwesomeIcon icon={faClock} />
                  <span>Durée: {circuit.duration}</span>
                </div>
                <div className="detail-item">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>Distance: {circuit.distance}</span>
                </div>
                <div className="detail-item">
                  <FontAwesomeIcon icon={faTag} />
                  <span>{circuit.price}</span>
                </div>
                <div className="detail-item">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span>Difficulté: {circuit.difficulty}</span>
                </div>
              </div>
              <p className="description">{circuit.description}</p>
              
              {circuit.highlights && (
                <div className="highlights">
                  <h4>Points forts:</h4>
                  <ul>
                    {circuit.highlights.map((highlight, idx) => (
                      <li key={idx}>{highlight}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              {circuit.stops && (
                <div className="stops">
                  <h4>Itinéraire:</h4>
                  <ul>
                    {circuit.stops.map((stop, idx) => (
                      <li key={idx}>{stop}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              {circuit.inclusions && (
                <div className="inclusions">
                  <h4>Inclusions:</h4>
                  <ul>
                    {circuit.inclusions.map((inclusion, idx) => (
                      <li key={idx}>{inclusion}</li>
                    ))}
                  </ul>
                </div>
              )}
              
              {circuit.hebergement && (
                <div className="hebergement">
                  <h4>Hébergement:</h4>
                  <ul>
                    {circuit.hebergement.map((hebergement, idx) => (
                      <li key={idx}>{hebergement}</li>
                    ))}
                  </ul>
                </div>
              )}
            </CircuitCard>
          ))}
        </CircuitsSection>

        <section>
          <SectionTitle>Nos Guides</SectionTitle>
          {tourismData.guides.map((guide, index) => (
            <GuideCard key={index}>
              <h3>{guide.name}</h3>
              <div className="info">
                <strong>Spécialité:</strong> {guide.specialite}
              </div>
              <div className="info">
                <strong>Expérience:</strong> {guide.experience}
              </div>
              <div className="langues">
                {guide.langues.map((langue, idx) => (
                  <span key={idx} className="langue">{langue}</span>
                ))}
              </div>
              <div className="regions">
                <strong>Régions:</strong>
                <ul>
                  {guide.regions.map((region, idx) => (
                    <li key={idx}>{region}</li>
                  ))}
                </ul>
              </div>
              <div className="info">
                <strong>Tarif:</strong> {guide.prix}
              </div>
            </GuideCard>
          ))}
        </section>
      </TourismContent>
    </TourismContainer>
  );
}

export default Tourism;
