import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import styled from 'styled-components'

const HeroContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background: linear-gradient(135deg, #002395 0%, #ED2939 100%);
`

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
`

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
`

const CTAButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #fff;
  color: #002395;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`

function FlagAnimation() {
  return (
    <mesh>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="#ED2939" />
    </mesh>
  )
}

function Hero() {
  return (
    <HeroContainer>
      <Canvas
        camera={{ position: [0, 0, 5] }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <FlagAnimation />
        <OrbitControls enableZoom={false} />
      </Canvas>
      
      <HeroContent>
        <Title>France-Maroc.fr</Title>
        <Subtitle>
          Votre portail pour les échanges culturels, économiques et éducatifs
          entre la France et le Maroc
        </Subtitle>
        <CTAButton onClick={() => window.location.href = '#initiatives'}>
          Découvrez Nos Initiatives
        </CTAButton>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
