import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Nav = styled.nav`
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #3498db;
  }
`

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    display: none;
  }
`

const NavLink = styled(Link)`
  color: #2c3e50;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  
  &:hover {
    color: #3498db;
  }
`

const BlogLink = styled.a`
  color: #2c3e50;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  
  &:hover {
    color: #3498db;
  }
`

function Navbar() {
  const navLinks = [
    { to: '/', text: 'Accueil' },
    { to: '/about', text: 'A Propos' },
    { to: '/cultural-exchange', text: 'Echanges Culturels' },
    { to: '/economic-opportunities', text: 'Opportunites Economiques' },
    { to: '/education', text: 'Education' },
    { to: '/tourism', text: 'Tourisme' },
    { to: '/news', text: 'Actualites' },
    { to: '/contact', text: 'Contact' }
  ]

  return (
    <Nav>
      <NavContainer>
        <Logo to="/">
          France-Maroc.fr
        </Logo>
        <NavLinks>
          {navLinks.map((link, index) => (
            <NavLink key={index} to={link.to}>
              {link.text}
            </NavLink>
          ))}
          <BlogLink href="/blog/" rel="noopener noreferrer">Blog</BlogLink>
        </NavLinks>
      </NavContainer>
    </Nav>
  )
}

export default Navbar
