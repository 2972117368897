import { useState } from 'react'
import styled from 'styled-components'

const GuideContainer = styled.div`
  padding: 4rem 2rem;
  background-color: white;
`

const GuideContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const CityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const CityCard = styled.div`
  background: ${props => props.active ? '#f8f9fa' : 'white'};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .content {
    padding: 1.5rem;
  }

  h3 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }

  p {
    color: #7f8c8d;
    font-size: 0.9rem;
  }
`

const CityDetails = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;
  display: ${props => props.visible ? 'block' : 'none'};

  h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 1rem;
  }

  .detail-section {
    h4 {
      color: #3498db;
      margin-bottom: 0.5rem;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 0.5rem;
        color: #7f8c8d;
      }
    }
  }
`

const cities = [
  {
    id: 1,
    name: 'Casablanca',
    image: '/images/casablanca.jpg',
    description: 'La plus grande ville du Maroc, centre économique et commercial',
    attractions: [
      'Mosquée Hassan II',
      'Quartier des Habous',
      'Corniche',
      'Morocco Mall'
    ],
    activities: [
      'Shopping dans les souks modernes',
      'Visite architecturale Art déco',
      'Promenade en bord de mer',
      'Dégustation de fruits de mer'
    ],
    culture: [
      'Mélange d\'architecture moderne et traditionnelle',
      'Centre d\'art contemporain',
      'Festivals de musique',
      'Scène gastronomique variée'
    ]
  },
  {
    id: 2,
    name: 'Marrakech',
    image: '/images/marrakech.jpg',
    description: 'La ville rouge, capitale touristique du Maroc',
    attractions: [
      'Place Jemaa el-Fna',
      'Jardin Majorelle',
      'Médina',
      'Palais Bahia'
    ],
    activities: [
      'Shopping dans les souks',
      'Cours de cuisine marocaine',
      'Hammam traditionnel',
      'Excursions dans l\'Atlas'
    ],
    culture: [
      'Arts traditionnels',
      'Festivals internationaux',
      'Gastronomie raffinée',
      'Artisanat local'
    ]
  },
  {
    id: 3,
    name: 'Fès',
    image: '/images/fes.jpg',
    description: 'La capitale spirituelle et culturelle du Maroc',
    attractions: [
      'Médina de Fès',
      'Université Al Quaraouiyine',
      'Tanneries',
      'Bab Boujloud'
    ],
    activities: [
      'Visite des ateliers d\'artisans',
      'Cours de calligraphie',
      'Découverte de la médina',
      'Dégustation de cuisine locale'
    ],
    culture: [
      'Centre spirituel',
      'Artisanat traditionnel',
      'Musique andalouse',
      'Architecture médiévale'
    ]
  },
  // Ajoutez d'autres villes ici
]

function MoroccanCityGuide() {
  const [selectedCity, setSelectedCity] = useState(null)

  return (
    <GuideContainer>
      <GuideContent>
        <h2>Guide des Villes Marocaines</h2>
        <CityGrid>
          {cities.map(city => (
            <CityCard
              key={city.id}
              active={selectedCity?.id === city.id}
              onClick={() => setSelectedCity(city)}
            >
              <img src={city.image} alt={city.name} />
              <div className="content">
                <h3>{city.name}</h3>
                <p>{city.description}</p>
              </div>
            </CityCard>
          ))}
        </CityGrid>

        <CityDetails visible={selectedCity !== null}>
          {selectedCity && (
            <>
              <h2>{selectedCity.name}</h2>
              <div className="details-grid">
                <div className="detail-section">
                  <h4>Attractions Principales</h4>
                  <ul>
                    {selectedCity.attractions.map((attraction, index) => (
                      <li key={index}>{attraction}</li>
                    ))}
                  </ul>
                </div>
                <div className="detail-section">
                  <h4>Activités Recommandées</h4>
                  <ul>
                    {selectedCity.activities.map((activity, index) => (
                      <li key={index}>{activity}</li>
                    ))}
                  </ul>
                </div>
                <div className="detail-section">
                  <h4>Culture et Traditions</h4>
                  <ul>
                    {selectedCity.culture.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}
        </CityDetails>
      </GuideContent>
    </GuideContainer>
  )
}

export default MoroccanCityGuide
