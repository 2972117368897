import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake, faChartLine, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

// Components will be imported here
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Footer from './components/Footer'
import Home from './pages/Home'
import About from './pages/About'
import CulturalExchange from './pages/CulturalExchange'
import EconomicOpportunities from './pages/EconomicOpportunities'
import Education from './pages/Education'
import News from './pages/News'
import Contact from './pages/Contact'
import Tourism from './pages/Tourism'

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const MainContent = styled.main`
  flex: 1;
  margin-top: 70px; // Pour compenser la hauteur de la navbar fixe
  background-color: #f5f5f5;
  min-height: calc(100vh - 70px);
`

function App() {
  return (
    <Router>
      <AppContainer>
        <Navbar />
        <MainContent>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/cultural-exchange" element={<CulturalExchange />} />
            <Route path="/economic-opportunities" element={<EconomicOpportunities />} />
            <Route path="/education" element={<Education />} />
            <Route path="/news" element={<News />} />
            <Route path="/tourism" element={<Tourism />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </MainContent>
        <Footer />
      </AppContainer>
    </Router>
  )
}

export default App
